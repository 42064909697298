import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);
  const clicked = () => setOpen(false);
  return (
    <header className="py-6">
      <div className="container flex justify-between items-center mx-auto px-8 md:px-14 lg:px-24 w-full">
        <div>
          <Link to="/" className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 mx-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <p className="text-xl font-bold hidden md:block items-center">
              st:th
            </p>
          </Link>
        </div>
        <div
          className={
            open
              ? "z-20 shadow-md flex flex-col absolute right-16 top-16 text-center bg-gray-800 py-8 px-16 rounded-md"
              : "hidden  md:flex space-x-12  items-center"
          }
        >
          <Link
            to="/"
            className="hover:text-selectedtext mb-8 md:mb-0"
            onClick={clicked}
          >
            Home
          </Link>
          <Link
            to="/about"
            className="hover:text-selectedtext mb-8 md:mb-0"
            onClick={clicked}
          >
            Über mich
          </Link>
          <Link
            to="/portfolio"
            className="hover:text-selectedtext mb-8 md:mb-0"
            onClick={clicked}
          >
            Portfolio
          </Link>
          <Link
            to="/quiz"
            className="hover:text-selectedtext mb-8 md:mb-0"
            onClick={clicked}
          >
            Quiz
          </Link>
          <Link to="/kontakt" className=" mb-8 md:mb-0" onClick={clicked}>
            <button className="px-6 py-2 bg-theme hover:bg-blue-800 rounded font-bold">
              Kontakt
            </button>
          </Link>
        </div>
        <div className="md:hidden cursor-pointer" onClick={handleToggle}>
          <svg
            width="26"
            height="18"
            viewBox="0 0 26 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 17.5H0.25V14.6667H13V17.5ZM25.75 10.4167H0.25V7.58333H25.75V10.4167ZM25.75 3.33333H13V0.5H25.75V3.33333Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </header>
  );
};
export default Header;
