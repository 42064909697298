import React, { useEffect, useState } from "react";

import axios from "axios";

const Quiz = () => {
  const URL = "https://opentdb.com/api.php?amount=10&type=multiple";

  const [error, setError] = useState(null);
  const [fragen, setFragen] = useState([]);

  //   function handleAnswer(e) {}

  useEffect(() => {
    axios
      .get(URL)
      .then((response) => {
        setFragen(response.data.results);
        console.log(response.data.results);
        // console.log(response.data.results[0].correct_answer);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);
  if (error) return `Error: ${error.message}`;
  if (!fragen) return "Ups keine Frage gefunden!";

  // const antArr = [
  //   { id: "antwort1", val: "frage.correct_answer" },
  //   { id: "antwort2", val: "frage.incorrect_answers[0]" },
  //   { id: "antwort3", val: "frage.incorrect_answers[1]" },
  //   { id: "antwort4", val: "frage.incorrect_answers[2]" },
  // ];

  return (
    <div className="container px-8 md:px-14 lg:px-24 mx-auto w-full ">
      {fragen.map((frage, index) => (
        <div className="fragebody ml-12 mb-4" key={index}>
          <div className="text-sm">{frage.category}</div>
          <div>{frage.question}</div>

          {/* {antArr.map((antw) => (
            <div id={antw.id}>{antw.val}</div>
          ))} */}

          <div className="frageantworten" id="antwort1">
            <label>
              <input type="radio" />
              <span>{frage.correct_answer}</span>
            </label>
          </div>
          {frage.incorrect_answers.map((falsch) => (
            <div className="frageantworten">
              <label>
                <input type="radio" />
                <span>{falsch}</span>
              </label>
            </div>
          ))}
          <button className=" mt-4 px-6 py-2 bg-theme hover:bg-blue-800 rounded font-bold">
            antworten
          </button>

          {/* <div className="frageantworten" id="antwort2">
            <label>
              <input type="radio" />
              <span>{frage.incorrect_answers[0]}</span>
            </label>
          </div>
          <div className="frageantworten" id="antwort3">
            <label>
              <input type="radio" />
              <span>{frage.incorrect_answers[1]}</span>
            </label>
          </div>
          <div className="frageantworten" id="antwort4">
            <label>
              <input type="radio" />
              <span>{frage.incorrect_answers[2]}</span>
            </label>
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default Quiz;
