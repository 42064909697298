import firebase from "firebase/compat/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDtXyNFgyLVfrlYBeHPAjQjXPR17K5sjkw",
  authDomain: "portfolio-form-c0f0e.firebaseapp.com",
  projectId: "portfolio-form-c0f0e",
  storageBucket: "portfolio-form-c0f0e.appspot.com",
  messagingSenderId: "576560326777",
  appId: "1:576560326777:web:5f8fa123ab51dbd2f54ca8",
};

firebase.initializeApp(firebaseConfig);

export { firebaseConfig as firebase };
