import React from "react";
import Wetter from "../Wetter/Wetter";

export const Footer = () => {
  return (
    <div className="container mt-4 py-6 md:fixed bottom-0">
      <footer className=" flex flex-col md:flex md:flex-row justify-between items-center w-screen mx-auto px-8 md:px-14 lg:px-24">
        <div>
          <p className="mx-4 px-8">&copy; 2021 Stefan Thiele</p>
        </div>
        <div className="hidden md:flex">
          <Wetter />
        </div>
        <div>
          <ul className="flex items-center pr-4">
            <li className="ml-4">
              <a href="mailto:kontakt@stefanthiele.net" rel="noreferrer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 social"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </a>
            </li>
            <li className="ml-4">
              <a
                href="//www.github.com/stilocybin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  className="h-6 w-6 social_1"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <g fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12z"
                      fill="#f8f8f8"
                    />
                  </g>
                </svg>
              </a>
            </li>
            <li className="ml-4">
              <a
                href="//www.linkedin.com/in/stefan-thiele-08b756226"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  className="h-6 w-6 social_1"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 16 16"
                >
                  <g fill="#f8f8f8">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248c-.015-.709-.52-1.248-1.342-1.248c-.822 0-1.359.54-1.359 1.248c0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586c.173-.431.568-.878 1.232-.878c.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252c-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                  </g>
                </svg>
              </a>
            </li>
            <li className="ml-4">
              <a
                href="//www.xing.com/profile/Stefan_Thiele54/cv"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  className="h-6 w-6 social_1"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M14.5 0h-13C.675 0 0 .675 0 1.5v13c0 .825.675 1.5 1.5 1.5h13c.825 0 1.5-.675 1.5-1.5v-13c0-.825-.675-1.5-1.5-1.5zM4.884 10.406H3.156a.253.253 0 0 1-.225-.119c-.047-.075-.047-.169 0-.266l1.838-3.244c.003-.003.003-.006 0-.009L3.6 4.743c-.047-.097-.056-.191-.009-.266c.044-.072.131-.109.237-.109h1.731c.266 0 .397.172.481.325c0 0 1.181 2.063 1.191 2.075c-.069.125-1.869 3.303-1.869 3.303c-.094.162-.219.334-.478.334zm8.185-8.028L9.238 9.153a.01.01 0 0 0 0 .012l2.441 4.456c.047.097.05.194.003.269c-.044.072-.125.109-.231.109H9.723c-.266 0-.397-.175-.484-.328L6.78 9.159l3.85-6.828c.094-.166.206-.328.463-.328h1.753c.103 0 .188.041.231.109c.044.072.044.169-.006.266z"
                    fill="#f8f8f8"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};
