export const links = [
  {
    id: "1",
    path: "/",
    name: "Home",
  },
  {
    id: "2",
    path: "/about",
    name: "Über_mich",
  },
  {
    id: "3",
    path: "/portfolio",
    name: "Portfolio",
  },
  {
    id: "4",
    path: "/quiz",
    name: "Quiz",
  },
  {
    id: "5",
    path: "/kontakt",
    name: "Kontakt",
  },
];
