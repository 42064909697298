import React, { useEffect, useState } from "react";
import axios from "axios";

const URL =
  "https://api.openweathermap.org/data/2.5/weather?q=Duesseldorf&lang=de&units=metric&appid=1dd32e42793423e2e6995c3130c543ac";

const Wetter = () => {
  const [error, setError] = useState(null);
  const [wetter, setWetter] = useState(null);

  useEffect(() => {
    axios
      .get(URL)
      .then((response) => {
        setWetter(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  if (error) return `Error: ${error.message}`;
  if (!wetter) return "No Wetter!";

  return (
    <div className="wetterinfo flex items-center">
      <p className="text-muted m-0 px-3">
        {wetter.name} {Math.floor(wetter.main.temp)} °C{" "}
        {wetter.weather[0].description}{" "}
      </p>
      <img
        src={`http://openweathermap.org/img/wn/${wetter.weather[0].icon}.png`}
        alt="wetter-icon"
      />
    </div>
  );
};

export default Wetter;
