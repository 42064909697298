export const projekte = [
  {
    id: "1",
    title: "C++ Bücher-Store",
    body: "This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    img: "/images/cppbibliothek.png",
    btn: "Github Repo",
    link: "//www.github.com/stilocybin/Mini_Bibliothek_in_Cpp",
  },
  {
    id: "2",
    title: "PHP Videothek",
    body: "This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    img: "/images/phpfilme.png",
    btn: "Github Repo",
    link: "//www.github.com/stilocybin/php_movie_db",
  },
  {
    id: "3",
    title: "JavaScript Pong",
    body: "This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    img: "/images/pong.png",
    btn: "Github Repo",
    link: "//www.github.com/stilocybin",
  },
  {
    id: "4",
    title: "ReactJS Quiz-App",
    body: "This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    img: "/images/quizapp.png",
    btn: "Github Repo",
    link: "//www.github.com/stilocybin",
  },
  {
    id: "5",
    title: "PHP Corona Tracker",
    body: "This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    img: "/images/corona.png",
    btn: "Github Repo",
    link: "//www.github.com/stilocybin/CoronaMVC",
  },
  {
    id: "6",
    title: "JS Warenkorb",
    body: "This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    img: "/images/warenkorb.png",
    btn: "Github Repo",
    link: "//www.github.com/stilocybin/JS_Warenkorb",
  },
];
