import { useState } from "react";
import { Link } from "react-router-dom";
import "./links";
import { links } from "./links";

const Navpoints = () => {
  const [active, setActive] = useState(false);

  const onClick = (e) => setActive(e.target.id);

  return (
    <div className="container mt-16 flex justify-between items-center mx-auto px-8 md:px-14 lg:px-24 w-full">
      <div className="flex flex-wrap md:flex-nowrap">
        <nav className="hidden  lg:mr-24 lg:w-4 fixed left-percentage md:block z-10">
          <div className="absolute left-50 transform -translate-x-1/2 space-y-6 mt-36">
            {links.map((link) => (
              <Link
                key={link.id}
                to={link.path}
                onClick={onClick}
                id={link.id}
                className={
                  active === link.id
                    ? "nav-dot block w-7 h-7 rounded-full border-4 border-nav bg-selectedtext hover:bg-theme"
                    : "nav-dot block w-7 h-7 rounded-full border-4 border-nav bg-body hover:bg-theme"
                }
              >
                <span className="bg-black px-4 py-2 rounded-md ml-10 opacity-0 pointer-events-none">
                  {link.name}
                </span>
              </Link>
            ))}
          </div>
        </nav>
      </div>
    </div>
  );
};
export default Navpoints;
