import React from "react";
import { Link } from "react-router-dom";
import Typed from "react-typed";
import { BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import homeTech from "../../assets/homebg5.png";

export const Home = () => {
  return (
    <div
      id="home"
      className=" lg:relative flex items-center md:items-start w-full mb-36"
    >
      <div className="container px-14 md:px-24 lg:px-36 mt-24 text-center md:text-left flex flex-col mx-auto items-center md:items-start">
        <Typed
          className="typedtext text-4xl text-selectedtext"
          strings={["Development", "Design", "Destroy"]}
          typeSpeed={80}
          backSpeed={80}
          loop
        ></Typed>
        <p className="text-8xl">
          {" "}
          mein <br />
          <span className="font-bold"> Portfolio</span>
        </p>
        <div className="flex">
          <Link to="/kontakt">
            <button className="mt-8 flex items-center px-8 py-3 bg-theme hover:bg-blue-800 border border-border rounded font-bold text-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>{" "}
              Kontakt
            </button>
          </Link>
          <Link to="/kontakt">
            <button className="mt-8 ml-8 flex items-center px-8 py-3 bg-body hover:bg-blue-800 border border-border rounded font-bold text-lg">
              <BsReverseLayoutTextWindowReverse className="mr-2" /> Lebenslauf
            </button>
          </Link>
        </div>
      </div>
      <div>
        <img
          src={homeTech}
          alt="home_tech"
          className=" -z-1 hidden xl:block xl:absolute right-0 top-0 max-w-5xl"
        />
      </div>
    </div>
  );
};
