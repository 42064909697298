import React from "react";
import { Link } from "react-router-dom";
import { projekte } from "./projekte";
export const Portfolio = () => {
  return (
    <div
      id="portfolio"
      className=" container  px-8 md:px-14 lg:px-24 mx-auto w-full"
    >
      <p className="text-4xl text-center mb-8"> Eine Auswahl meiner Projekte</p>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 pb-48 ">
        {projekte.map((projekt, id) => (
          <div className="grid grid-rows-1 mx-auto">
            <div className="max-w-xs rounded overflow-hidden shadow-lg ">
              <img
                className="w-full"
                src={projekt.img}
                alt="projekt thumbnails"
              />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{projekt.title}</div>
                <p className="text-white text-base">{projekt.body}</p>
              </div>
              <div className="px-6 pt-4 pb-2">
                <Link to={projekt.link} target="_blank">
                  <button className="px-6 py-2 bg-theme hover:bg-blue-800 rounded font-bold">
                    {projekt.btn}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
