import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export const Kontakt = () => {
  const db = firebase.firestore();
  const [message, setMessage] = useState({
    name: "",
    email: "",
    subject: "",
    text: "",
  });

  const onChange = (e) => {
    setMessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(message);
    db.collection("message").add(message);
    setMessage({
      name: "",
      email: "",
      subject: "",
      text: "",
    });
    alert("Danke für Ihre Nachricht!");
  };

  return (
    <div>
      <div>
        <p className="text-center text-3xl mb-8">
          Schreiben Sie mir eine Nachricht!
        </p>
      </div>
      <form className="max-w-xl mx-auto" onSubmit={onSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="first-name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={message.name}
              onChange={onChange}
              className="form-input px-3 py-2 rounded-sm bg-gray-300  text-black"
              required
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={message.email}
              onChange={onChange}
              className="form-input px-3 py-2 rounded-sm bg-gray-300  text-black"
              required
            />
          </div>

          <div className="flex flex-col col-span-2">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={message.subject}
              onChange={onChange}
              className="form-input px-3 py-2 rounded-sm bg-gray-300 text-black"
              required
            />
          </div>
          <div className="flex flex-col col-span-2">
            <label htmlFor="subject">
              <div className="flex align-items">
                Message
                <span className="ml-auto opacity-75">Max. 500 characters</span>
              </div>
            </label>
            <textarea
              maxLength="500"
              rows="4"
              type="text"
              id="text"
              name="text"
              value={message.text}
              onChange={onChange}
              className="form-input px-3 py-2 rounded-sm bg-gray-300 text-black"
              required
            />
          </div>
        </div>
        <div className="flex justify-end py-4">
          <button
            // onClick={handleSubmit}
            type="submit"
            className="px-6 py-2 bg-theme hover:bg-blue-800 rounded font-bold"
          >
            Senden
          </button>
        </div>
      </form>

      <div className="mt-8 text-center">
        <p>
          Alternativ können Sie mir eine E-Mail an{" "}
          <a
            className="font-bold text-selectedtext"
            href="mailto:kontakt@stefanthiele.net"
          >
            kontakt@stefanthiele.net
          </a>{" "}
          schreiben!
        </p>
      </div>
    </div>
  );
};
