import React, { useState } from "react";
// import { firebase } from "../../setupFirebase";
import firebase from "firebase/compat/app";

export const Nachrichten = () => {
  const [nachricht, setNachricht] = useState([]);

  window.addEventListener("load", () => {
    getNachricht();
  });

  const getNachricht = () =>
    firebase
      .firestore()
      .collection("message")

      .get()
      .then((QuerySnapshot) => {
        QuerySnapshot.forEach((element) => {
          let data = element.data();
          setNachricht((arr) => [...arr, data]);
        });
      });

  return (
    <div className="container flex ">
      {nachricht.map((data) => (
        <>
          <div className="mx-8">
            <p>Name: {data.name}</p>
            <p>E-Mail: {data.email}</p>

            <p>Subject: {data.subject}</p>
            <p>Nachricht: {data.text}</p>
          </div>
        </>
      ))}
    </div>
  );
};
