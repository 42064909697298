import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { About } from "./components/About/About";
import { Footer } from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { Home } from "./components/Home/Home";
import { Kontakt } from "./components/Kontakt/Kontakt";
import { Nachrichten } from "./components/Nachrichten/Nachrichten";

import Navpoints from "./components/Navpoints/Navpoints";
import { Portfolio } from "./components/Portfolio/Portfolio";
import Quiz from "./components/Quiz/Quiz";

function App() {
  return (
    <Router>
      <Header />
      <Navpoints />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/nachrichten" element={<Nachrichten />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
